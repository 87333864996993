import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import 'uikit/dist/css/uikit.min.css'
import './css/layout.css'
import './less/layout.less'
import './less/fonts.less'
import Nav from './nav'
import Footer from './footer'
import Instagram from './instagram'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBars,
    faChevronDown,
    faTimes,
    faEnvelope,
    faPhone,
    faMobile,
    faWrench,
} from '@fortawesome/free-solid-svg-icons'
library.add(faBars)
library.add(faChevronDown)
library.add(faTimes)
library.add(faEnvelope)
library.add(faPhone)
library.add(faMobile)
library.add(faWrench)


const Layout = ({ children, containerClass }) => (
    
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={data => {
            return (
            <>  
                <Nav/>
                <div className={containerClass}>
                    {children}
                    
                </div>
                <Instagram/>
                <Footer/>
            </>
        ) }
    }
    />
)



Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

