import React from 'react'
import { Link } from 'gatsby'
import logoSrc from '../assets/logo.png'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Nav extends React.Component {

    constructor(props){
        super(props);
            this.state = {
                scrolled: false,
                navActive: false,
                linksArray: [
                    {
                        slug: '/',
                        name: 'Home',
                    },
                    {
                        slug: 'fabrication',
                        name: 'Fabrication',
                    },
                    {
                        slug: 'construction',
                        name: 'Construction',
                    },
                    {
                        slug: 'design',
                        name: 'Design / Drafting'
                    },
                    {
                        slug: 'about',
                        name: 'About',
                    },
                    {
                        slug: 'terms',
                        name: 'Terms of Trade',
                    }
                ]  
            }
        this.toggleNav = this.toggleNav.bind(this);
        this.dropdownClass = this.dropdownClass.bind(this);
        
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        if(window.pageYOffset > 400){
            this.setState({
                scrolled: true
            })
        }
        else{
            this.setState({
                scrolled: false
            })
        }
    }
    
    
    toggleNav(){
        let update_to = true;
        if(this.state.navActive){ // Need to close 
            update_to = false
        }
        this.setState(function(){
          return {
            navActive: update_to
          }
        })    
    }

    navbarClass(){
        let class_string = 'uk-position-fixed uk-width-1-1 uk-navbar  uk-navbar-transparent';
        if(this.state.scrolled){
            return class_string + ' scrolled'
        }
        return class_string
    }

    dropdownClass(){
        if(this.state.navActive){
            return 'uk-navbar-dropdown uk-open';
        }
        return 'uk-navbar-dropdown';
    }


    buttonState(){
        if(this.state.navActive){
            return {
                class: 'active',
                contents: <FontAwesomeIcon icon="times" />
            }
        }
            return {
            class: '',
            contents: <FontAwesomeIcon icon="bars" />
        }
    }
    

    render(){
        let dropdownClass = this.dropdownClass();
        let buttonState =  this.buttonState();
        let navbarClass = this.navbarClass();
        return (
            <nav className={navbarClass} id="main-nav">
                <div className="uk-navbar-left">
                    <Link to="/" className="logo">
                        <img src={logoSrc}/>
                    </Link>
                </div>
                <ul className="uk-nav uk-navbar-right uk-visible@l desktop-navigation">
                    {this.state.linksArray.map((link, i) => (
                        <li key={i}><Link
                            to={link.slug}
                            key={i}
                            className="navbar-item"
                            activeClassName="active"
                        >
                            {link.name}
                        </Link></li>
                    ))}
                        <li>
                            <AnchorLink href="#contact">Contact</AnchorLink>
                        </li>
                </ul>
                
                <div className="uk-position-fixed burger-container uk-hidden@l">
                    <a onClick={this.toggleNav} className={buttonState.class} id="burger">{buttonState.contents}</a>
                    <div className={dropdownClass}>
                        <ul className="uk-nav uk-navbar-dropdown-nav">
                        {this.state.linksArray.map((link, i) => (
                            <li key={i}><Link
                                to={link.slug}
                                key={i}
                                className="navbar-item"
                                activeClassName="active"
                            >
                                {link.name}
                            </Link></li>
                        ))}
                            <li>
                                <AnchorLink href="#contact">Contact</AnchorLink>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>
        )
    }    
}
export default Nav
