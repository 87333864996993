import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default () => (
    <StaticQuery
        query={graphql`
            query footerQuery {
                footer_1:file(relativePath: {  eq: "footer/sitesafe.png" } ) {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                footer_2:file(relativePath: { eq: "footer/lbp-origin.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => {
            return (
                <footer id="contact">
                  <section className="info uk-height-1-1 pad-100">
                      <div className="uk-container uk-container-center uk-height-1-1">
                          <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-wrap uk-height-1-1">
                              <div className="uk-width-1-1 uk-width-1-2@l">
                              <form
                                name="contact"
                                method="post"
                                action="/thanks"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                >
                                    <input className="uk-hidden" type="hidden" name="form-name" value="contact"/>
                                      <fieldset className="uk-fieldset">
                                      <h3 className="h1 text-white">Contact Us</h3>
                                          <div className="uk-margin">
                                              <input
                                                  className="uk-input"
                                                  type="text"
                                                  name="name"
                                                  placeholder="Name"
                                                  required
                                              />
                                          </div>
                                          <div className="uk-margin">
                                              <input
                                                  className="uk-input"
                                                  type="text"
                                                  name="email"
                                                  placeholder="Email"
                                                  required
                                              />
                                          </div>
                                          <div className="uk-margin">
                                              <input
                                                  className="uk-input"
                                                  type="text"
                                                  placeholder="Phone"
                                                  name="phone"
                                              />
                                          </div>
                                          <div className="uk-margin">
                                              <textarea
                                                  className="uk-textarea"
                                                  rows="5"
                                                  placeholder="Message"
                                                  name="message"
                                                  required
                                              />
                                          </div>
                                          <div className="uk-margin">
                                            <button className="uk-button" type="submit">
                                                Submit
                                            </button>
                                            </div>
                                      </fieldset>
                                  </form>
                              </div>
                              <div className="uk-width-1-1 uk-width-1-2@l">
                              
                                  <ul className="details uk-nav">
                                      <li>
                                          <a href="mailto:sales@allcor.co.nz">
                                              <FontAwesomeIcon icon="envelope" />
                                              sales@allcor.co.nz
                                          </a>
                                      </li>
                                      <li>
                                          <a href="tel:+6434554414">
                                              <FontAwesomeIcon icon="phone" />
                                              03 455 4414
                                          </a>
                                      </li>
                                      <li>
                                          <a href="https://goo.gl/maps/tXxV3ddnvoP2" target="_blank">
                                              <FontAwesomeIcon icon="wrench" />
                                              13 Lorne Street,
                                              South Dunedin, Dunedin
                                          </a>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </section>
                    <section className="info" style={{backgroundColor: '#fff'}}>
                        <div className="uk-container uk-container-center">
                            <div className="uk-flex uk-flex-space-between uk-flex-middle uk-flex-wrap">
                            <div className="uk-width-1-1 uk-width-1-2@l">
                                <Img fluid={data.footer_1.childImageSharp.fluid}/>
                            </div>
                            <div className="uk-width-1-1 uk-width-1-2@l">
                                <Img fluid={data.footer_2.childImageSharp.fluid} style={{width: "50%", margin: "0 auto"}}/>
                            </div>
                            </div>
                        </div>
                    </section>
                  <div className="socket">
                      <div className="uk-container uk-container-center">
                          <p className="uk-float-left">&copy; 2019 Allcor</p>
                          <p className="uk-float-right">
                              Developed by <a target="_blank" href="https://octagonal.io">Octagonal.io</a>
                          </p>
                      </div>
                  </div>
              </footer>
            )
        }}
    />
)
